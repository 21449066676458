import { Component } from '@angular/core';
import { QrcodeComponent } from '../../shared/qrcode/qrcode.component';

@Component({
  selector: 'madero-home',
  standalone: true,
  imports: [
    QrcodeComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
