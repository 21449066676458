<div class="c-login">
  <div class="c-login__left">
    <div class="c-header">
      <img class="c-header__logo" src="../../../assets/madero-logo.svg" />
      <h1 class="c-header__title">CARDCONTROL</h1>
    </div>
    <form
      class="c-login-form"
      appearance="outline"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field appearance="outline" class="c-login-form__mail">
        <mat-label>Username</mat-label>
        <input
          matInput
          placeholder="Usuário..."
          formControlName="mail"
          placeholder="Insira seu usuário"
        />
        <mat-icon matSuffix color="primary" class="material-symbols-rounded">
          person
        </mat-icon>

        @if (form.get('mail')?.hasError('required')) {
        <mat-error>Insira seu e-mail</mat-error>
        } @if (form.get('mail')?.hasError('email')) {
        <mat-error>Coloque um e-mail válido</mat-error>
        } @if (form.hasError('required')) {
        <mat-error>Insira seu <strong>usuário.</strong></mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="c-login-form__password">
        <mat-label>Senha</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          placeholder="Insira sua senha"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon class="" color="primary" class="material-symbols-rounded">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
        <mat-error *ngIf="form.get('password')?.hasError('required')"
          >Insira uma senha</mat-error
        >
      </mat-form-field>

      <button
        mat-raised-button
        class="c-btn c-btn--primary"
        type="submit"
        [disabled]="!form.valid"
      >
        @if (loading) {
        <div class="c-btn-spinner">
          <mat-spinner class="c-btn--spinner"></mat-spinner>
          <span>Carregando...</span>
        </div>
        } @else {
        <span>Entrar</span>
        }
      </button>
    </form>
  </div>
  <div class="c-login__right">
    <footer class="c-footer">
      Copyright Madero {{ currentYear }} - Política e Termos de Uso
    </footer>
  </div>
</div>
