import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../core/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  form!: FormGroup;
  hide: boolean = true;
  loading: boolean = false;
  currentYear = new Date().getFullYear();

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _dialogRef: MatDialog,
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
  }

  onBuildForm(): void {
    this.form = this.fb.group({
      mail: [null, [Validators.required]],
      password: [null, Validators.required],
    });
  }

  forgotPassword() {
    const dialogRef = this._dialogRef.open(ForgotPasswordComponent, {
      autoFocus: false,
      minWidth: '350px',
      panelClass: 'custom-dialog-container',
      disableClose: false,
      data: '',
    });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        console.log(x);
      }
    });
  }

  onSubmit(): void {
    this.loading = true;
    const formResult = this.form.getRawValue();

    this.authService.onRequestLogin(formResult.mail, formResult.password).subscribe({
      next: (user) => {
        this.authService.user$.next(user);
        this.authService.token$.next(user.access_token);
        sessionStorage.setItem('token', user.access_token);
        sessionStorage.setItem('user', JSON.stringify(user));
        this._router.navigate(['/home']);
      },
      error: (err) => {
        this.loading = false;
        this._snackBar.open(
          'Houve um problema ao tentar fazer o login!',
          'fechar',
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
