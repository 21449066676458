import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../environments/environments';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  token$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  client_id: string = "client_giftcard_teknisa";
  client_secret: string = "46e56ca3-686c-ef5d-d0a1-81e3aebf4718";
  grant_type: string = "password";
  scope: string = "grupomadero_giftcard_api grupomadero_giftcard_api.giftcard_write grupomadero_giftcard_api.giftcard_read openid";

  username: string = 'giftcard.teknisa'
  password: string = 'U#ImFkTtEcb4wo9'

  constructor(
    private http: HttpClient, 
    private router: Router
  ) {}

  onRequestLogin(username: string, pass: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const httpOptions = {
      headers: headers,
    };

    const payload = new HttpParams()
      .set('username', username)
      .set('password', pass)
      .set('client_id', this.client_id)
      .set('client_secret', this.client_secret)
      .set('grant_type', this.grant_type)
      .set('scope', this.scope);

    return this.http.post<any>(
      `${environment.AUTH_API}connect/token`,
      payload.toString(),
      httpOptions,
    );
  }

  logOut(): Observable<any> {
    sessionStorage.clear()
    return of(this.router.navigate(['/']));
  }

}
