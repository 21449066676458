<div class="c-container">
    <img src="../assets/img/CARD-CONTROL.svg" alt="" srcset="">

    <hr>
    
    <h3>Aponte a câmera para o QR Code ou digite o código</h3>
    
    @defer {
        <madero-qrcode></madero-qrcode>
    } @placeholder {
        <p>Carregando QR scanner...</p>
    }
</div>